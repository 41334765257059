// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core';
// import { AppModule } from './app/app.module';
import { DialogModule } from '@angular/cdk/dialog';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, provideRouter } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";
import { provideAngularSvgIcon } from 'angular-svg-icon';
import jwt_decode from "jwt-decode";
import { EMPTY } from 'rxjs';
import { routes } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { tokenInterceptor } from './app/core/interceptors/token.interceptor';
import { User } from './app/core/models/user.model';
import { JwtService } from './app/core/services/jwt.service';
import { OrganisationsService } from './app/core/services/organisations.service';
import { UserService } from './app/core/services/user.service';

if (!isDevMode) {
  Sentry.init({
    dsn: "https://1eaa95f7a5f6319e20c2a7655374d031@o416754.ingest.sentry.io/4506438843826176",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


export function initAuth(jwtService: JwtService, userService: UserService, organisationService: OrganisationsService) {
  return () => {
    const token = jwtService.getToken();
    if (token) {
      const {
        userId: id,
        displayName,
        email,
        imageUrl,
        username
      } = jwt_decode<{
        displayName: string;
        email: string;
        userId: number;
        username: string;
        imageUrl: string;
      }>(token);
      const user: User = {
        id,
        displayName,
        username,
        email,
        imageUrl,
        role: ''
      };
      userService.setAuth(token, user);
      return organisationService.query();
    }
    return EMPTY;
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [JwtService, UserService, OrganisationsService],
      multi: true
    },
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([tokenInterceptor])
    ),
    provideAngularSvgIcon(),
    importProvidersFrom(DialogModule),
    provideAnimations()
  ]
});
