import { z } from 'zod';
import { SurveySchema } from "./survey.model";
import { ProjectUserSchema, UserSchema } from './user.model';
import { PreSurveySchema } from './presurvey.model';
import { SpeciesFeatureNoteSchema } from './speciesfeaturenote.model';


const PolygonSchema = z.object({
  type: z.literal('Polygon'),
  coordinates: z.array(z.array(z.array(z.number())))
});

const MultiPolygonSchema = z.object({
  type: z.literal('MultiPolygon'),
  coordinates: z.array(z.array(z.array(z.array(z.number()))))
});

// const ProjectGeometrySchema = z.union([PolygonSchema, MultiPolygonSchema]);
const ProjectGeometrySchema = MultiPolygonSchema;

export const ProjectSummarySchema = z
  .object({
    id: z.number(),
    name: z.string(),
    description: z.string().nullable(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    geometry: ProjectGeometrySchema.nullable(),
    ukHabVersion: z.string(),
    organisationId: z.number()
  })
  .strict();

export const ProjectVerificationStateSchema = z.object({
  stateId: z.number(),
  name: z.string(),
  color: z.string()
});
export type ProjectVerificationState = z.infer<typeof ProjectVerificationStateSchema>;

export type ProjectSummary = z.infer<typeof ProjectSummarySchema>;

export const ProjectDetailsSchema = ProjectSummarySchema.extend({
  role: z.string(),
  shareableLink: z.string(),
  states: ProjectVerificationStateSchema.array()
}).strict();

const ProjectMembershipSchema = z.object({
  role: z.string(),
  userId: z.number()
});

export type ProjectDetails = z.infer<typeof ProjectDetailsSchema>;
export type ProjectGeometry = z.infer<typeof ProjectGeometrySchema>;
export type Project = ProjectDetails & {
  surveys: z.infer<typeof SurveySchema>[];
  users: z.infer<typeof ProjectUserSchema>[];
  presurveys: z.infer<typeof PreSurveySchema>[];
  states: ProjectVerificationState[];
}

export type ProjectUpdate = Partial<Pick<ProjectDetails, 'name' | 'description' | 'geometry'>>;

export type ProjectMembership = z.infer<typeof ProjectMembershipSchema>;
