import { z } from 'zod';



export const UserSchema = z
  .object({
    id: z.number(),
    username: z.string(),
    imageUrl: z.union([z.null(), z.string()]).optional(),
    displayName: z.string(),
    email: z.string().optional(),
    role: z.string()
  })
  .strict();

export type User = z.infer<typeof UserSchema>;
// export type UserOrganisation = z.infer<typeof UserOrganisationSchema>;

export const ProjectUserSchema = z.object({
  userId: z.number(),
  username: z.string(),
  imageUrl: z.union([z.null(), z.string()]).optional(),
  displayName: z.string(),
  email: z.string().optional(),
  role: z.string()
}).strict();
export type ProjectUser = z.infer<typeof ProjectUserSchema>;
