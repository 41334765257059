import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { Survey } from './core/models/survey.model';
import { ProjectService } from './features/project/project.service';

export const surveyResolver: ResolveFn<Survey> = (route) => {

  const projectService = inject(ProjectService);
  const router = inject(Router);

  const surveyId = parseInt(route.paramMap.get("surveyid") as string);
  const project = projectService.project()!;
  const baselines = projectService.baseline();
  const survey = baselines.find((a) => a.id === surveyId);

  projectService.activeBaselineId.set(surveyId);

  if (typeof survey === 'undefined') {
    router.navigate(['/']);
    return EMPTY;
  }

  if (project.ukHabVersion === 'ukhabappv3') {
    const baseline = projectService.baseline();
    return of(baseline.find((a) => a.id === surveyId)!);
  }
  return of(survey);
}

