import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal } from "@angular/core";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ProjectDetails, ProjectMembership, ProjectSummary, ProjectSummarySchema } from "../models/project.model";

import { MultiPolygon, Polygon } from "geojson";

@Injectable({ providedIn: 'root' })
export class ProjectsService {

  httpClient = inject(HttpClient);

  public readonly currentProject = signal<ProjectDetails | null>(null);
  public readonly projects = signal<ProjectSummary[] | undefined>(undefined);

  query(id: number) {
    return this.httpClient.get<ProjectSummary[]>(`${environment.ukhabApiUrl}/organisations/${id}/projects`).pipe(
      map(response => {
        return ProjectSummarySchema.array().parse(response);
      })
    );
  }

  setCurrentProject(project: ProjectDetails) {
    this.currentProject.set(project);
  }

  clearCurrentProject() {
    this.currentProject.set(null);
  }

  updateCurrentProject(project: Partial<ProjectDetails>) {
    this.currentProject.update(current => ({
      ...current!,
      ...project,
      id: current!.id,
    }));
  }

  deleteProject(id: number) {
    const url = `${environment.ukhabApiUrl}/projects/${id}`;
    return this.httpClient.delete(url);
  }

  createProject(organisationId: number, name: string, description: string, memberships: ProjectMembership[], geometry?: MultiPolygon) {
    return this.httpClient.post<{ id: number }>(`${environment.ukhabApiUrl}/organisations/${organisationId}/projects`, {
      name,
      description,
      geometry,
      memberships
    }).pipe(
      tap(response => {
        const projects: ProjectSummary[] = [
          ...(this.projects() ?? []),
          {
            id: response.id,
            name,
            description,
            organisationId,
            geometry: geometry ?? null,
            ukHabVersion: 'ukhabappv3',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          }
        ];
        projects.sort((a, b) => a.name.localeCompare(b.name));
        this.projects.set(projects);
      }),
      map(response => response.id)
    );
  }
}
