import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { JwtService } from "../services/jwt.service";


export const tokenInterceptor: HttpInterceptorFn = (req, next) => {

  if (req.url.startsWith('https://coreo.s3')) {
    return next(req);
  }
  const jwtService = inject(JwtService);
  const token = jwtService.getToken();
  const request = req.clone({
    setHeaders: {
      ...(token ? { Authorization: `JWT ${token}` } : {})
    }
  });
  return next(request);
}
