import { inject } from "@angular/core";
import { ResolveFn, Router } from "@angular/router";
import { EMPTY, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ProjectDetails } from "./core/models/project.model";
import { OrganisationsService } from "./core/services/organisations.service";
import { ProjectsService } from "./core/services/projects.service";
import { ProjectService } from "./features/project/project.service";

export const projectResolver: ResolveFn<ProjectDetails> = (route) => {
  const projectService = inject(ProjectService);
  const organisationService = inject(OrganisationsService);
  const projectsService = inject(ProjectsService);
  const router = inject(Router);

  const projectId = parseInt(route.paramMap.get("id")!);
  return projectService.get(projectId).pipe(mergeMap(project => {
    if (project) {
      organisationService.setOrganisation(project.organisationId);
      projectsService.setCurrentProject(project);
      return of(project);
    }
    router.navigate(['/']);
    return EMPTY;
  }));
};
